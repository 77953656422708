
import { defineComponent } from "vue";
import { ElLoading } from "element-plus";
// import CustomSelect from "@/components/Input/SelectBox.vue";
import ColorPicker from "@/components/Input/ColorPicker.vue";
import {
  getStorage,
  ref,
  uploadString,
  // uploadBytesResumable,
} from "firebase/storage";
import _ from "lodash";
import helpers from "@/helpers/global";

export default defineComponent({
  name: "CreateAccount",
  components: {
    ColorPicker,
  },
  created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.toolKey = urlSearchParams.get("tool") || "";

    if (localStorage.getItem("userData")) {
      this.$router.replace(this.toolKey ? `/${this.toolKey}` : "/");
      return;
    }
    this.initDefaultValues();

    const signupFormDetails = this.$store.getters["auth/signupFormDetails"];
    if (_.has(signupFormDetails, "email")) {
      this.$store.dispatch(
        "tracking/save",
        {
          ns: "signup.branding.view",
          group: "signup",
          email: _.get(signupFormDetails, "email"),
        },
        { root: true }
      );
    }

    this.$store.dispatch("forceReady", null, { root: true });
  },
  watch: {
    error(value) {
      console.error(value);
    },
    userLogin(value) {
      if (value) {
        this.$router.push("/");
      }
    },
    loadingStatus(value) {
      const loading = ElLoading.service({
        lock: true,
        text: "Login..",
        background: "#ffffff90",
      });
      if (!value) {
        loading.close();
      }
    },
    hexCode(value) {
      this.form.color = value;
      if (value !== "") {
        const rgx = /^#[0-9a-f]{3,6}$/i;
        this.hexValidate = !rgx.test(value);
      } else {
        this.hexValidate = false;
      }
    },
  },
  computed: {
    userLogin(): any {
      return this.$store.getters.getLoginStatus;
    },
    isImage(): any {
      return (
        this.selectedFile && (this.selectedFile as any).type.includes("image")
      );
    },
    // @see views/Assessments/Create/SetupValues&Traits.vue
    valuesAndTraits(): any {
      const valuesAndTraits =
        this.$store.getters["assessmentSection/valuesAndTraits"];
      if (
        valuesAndTraits &&
        valuesAndTraits.scenarios &&
        valuesAndTraits.scenarios.definingYourValues &&
        valuesAndTraits.scenarios.definingYourValues.questions
      ) {
        // helpers.shuffleArray(
        //   valuesAndTraits.scenarios.definingYourValues.questions
        // );
        valuesAndTraits.scenarios.definingYourValues.questions.sort(
          (a: any, b: any) => {
            const nameA = a.title.toUpperCase(); // ignore upper and lowercase
            const nameB = b.title.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }
        );
      }
      return valuesAndTraits;
    },
  },
  data() {
    return {
      hexCode: "",
      hexValidate: false,
      selectedFile: null,
      previewUrl: "",
      submitLoading: false,
      skipLoading: false,
      form: {
        // color: "#ffffff",
        color: "#000000", // default company color
        imageFile: "",
        companyUploadedFile: new File([], ""),
        companyUploadedFileDataUrl: "",
        companyLogoUrl: "",
        coreValuesSelected: [] as string[],
      },
      errors: {
        coreValuesSelected: false,
      },
      toolKey: "",
    };
  },
  async mounted() {
    if (this.userLogin) {
      this.$router.push("/");
      return;
    }
    await this.$store.dispatch("assessmentSection/initValuesAndTraits", null, {
      root: true,
    });
    await this.$store.dispatch(
      "assessmentSection/loadSetupValuesAndTraits",
      null,
      {
        root: true,
      }
    );
  },
  methods: {
    initDefaultValues() {
      this.$store.dispatch("auth/initSignupFormDetails", null, { root: true });
      const signupFormDetails = this.$store.getters["auth/signupFormDetails"];
      this.$store.dispatch("auth/applySignupFormDetails", signupFormDetails, {
        root: true,
      });
    },
    lang(obj: any, attr: string) {
      return helpers.objectLang(obj, attr);
    },
    onColorChange(val: any) {
      this.hexCode = val.hex;
    },
    handleAvatarSuccess(res: any) {
      this.form.imageFile = URL.createObjectURL(res.file);

      const reader = new FileReader();
      reader.addEventListener("load", (/*event: ProgressEvent*/) => {
        this.form.companyUploadedFile = res.file;
        this.form.companyUploadedFileDataUrl = String(reader.result);
      });
      reader.readAsDataURL(res.file);
    },
    handleFileUpload(event: any) {
      const file = event.target.files[0];
      this.selectedFile = file;

      if (file && file.type.includes("image")) {
        this.previewUrl = URL.createObjectURL(file);
        this.handleAvatarSuccess({ file });
      } else {
        this.previewUrl = "";
      }
    },
    validate(): boolean {
      // this.errors.coreValuesSelected = this.form.coreValuesSelected.length == 0;
      if (this.errors.coreValuesSelected) return false;
      return true;
    },
    async signup() {
      if (!this.validate()) return false;
      this.submitLoading = true;
      await this.newStep();
    },
    async newStep() {
      // this.checkingValidate(this.form, true);
      if (!this.hexValidate) {
        // console.log("Home page");
        const file = this.form.companyUploadedFile;
        const dataUrl = this.form.companyUploadedFileDataUrl;
        // Can't delete property from object with typescript
        let userDetails = _.omit(this.form, [
          "companyUploadedFile",
          "companyUploadedFileDataUrl",
          "imageFile",
          "coreValuesSelected",
        ]) as any;

        userDetails.isGuest = false;

        userDetails.coreValues = this.form.coreValuesSelected;

        // @see https://trello.com/c/ZC5FrRR6/272-onboarding-as-a-user-i-want-to-see-a-clear-path-to-creating-a-new-assessment-so-that-im-not-confused-about-what-to-do-next
        // userDetails.autoGenerateForm = userDetails.coreValues.length > 0;
        userDetails.autoGenerateForm = false;

        // Guided Tour
        userDetails.guidedTourCreateEnabled = true;
        userDetails.guidedTourCreateDone = false;

        const createUser = async (userDetails: any) => {
          // console.log("createUser()");
          // console.log(userDetails, "userDetails");
          this.$store.dispatch("auth/setSignupBranding", userDetails, {
            root: true,
          });
          let $userDetails = _.omit(
            this.$store.getters["auth/signupFormDetails"] || {},
            ["companyUploadedFile", "companyUploadedFileDataUrl", "imageFile"]
          );
          // console.log($userDetails, "$userDetails");
          // if ($userDetails) return;
          this.$store.commit("loadingStatus", true);

          await this.$store.dispatch(
            "tracking/save",
            {
              ns: "signup.branding",
              group: "signup",
              email: $userDetails.email,
            },
            { root: true }
          );

          const referralCode = helpers.getReferralCode();
          if (referralCode) {
            $userDetails["referral"] = referralCode;
          }

          await this.$store.dispatch("user/createUser", $userDetails, {
            root: true,
          });

          setTimeout(async () => {
            if (this.$store.getters["auth/error"]) {
              // this.emailWarningText = this.$store.getters["auth/error"];
              await this.$store.dispatch(
                "tracking/save",
                {
                  ns: "signup.branding.error",
                  group: "signup",
                  email: $userDetails.email,
                  message: this.$store.getters["auth/error"],
                },
                { root: true }
              );
              return;
            } else {
              await this.$store.dispatch(
                "tracking/save",
                {
                  ns: "signup.branding.success",
                  group: "signup",
                  email: $userDetails.email,
                },
                { root: true }
              );
            }

            // binding OAuth Account
            const ns = "CreateAccountForm";
            const oAuthProvider = this.$store.getters[ns + "/oAuthProvider"];
            const oAuthResult = this.$store.getters[ns + "/oAuthResult"];
            if (oAuthProvider && oAuthResult) {
              const oAuthDispatchType = oAuthProvider + "/upsertAccount";
              await this.$store.dispatch(oAuthDispatchType, oAuthResult);
            }

            this.$store.dispatch("auth/clearSignupFormDetails", null, {
              root: true,
            });

            const callback = () => {
              let redirectUrl = this.$store.getters["auth/redirectUrl"] || "/";
              // @todo goto lang key
              if (this.toolKey) {
                redirectUrl = `/${this.toolKey}`;
              }
              const delay = referralCode ? 5000 : 1500;
              setTimeout(() => {
                this.$store.commit("loadingStatus", false);
                window.location.href = redirectUrl;
              }, delay);
            };

            this.$store.dispatch(
              "auth/loginAsUser",
              { email: $userDetails.email, callback: callback },
              {
                root: true,
              }
            );
          }, 1000);
        };

        // @note upload by string (data url)
        if (dataUrl && file) {
          const storage = getStorage();
          const { email } = this.$store.getters["auth/signupFormDetails"] || {};
          const emailKey = helpers.emailKey(email);
          if (file instanceof File) {
            const prefix = "company";
            const guid = helpers.guid();
            let filename = file.name;
            if (filename.indexOf(".") !== -1) {
              const chunks = filename.split(".");
              const ext = chunks.pop();
              filename = chunks.join(".") + "-" + guid + "." + ext;
            }
            const path = `images/users/${emailKey}/${prefix}-${filename}`;
            const storageRef = ref(storage, path);
            uploadString(storageRef, dataUrl, "data_url").then(
              async (snapshot) => {
                userDetails.companyLogoUrl = snapshot.metadata.fullPath;
                await createUser(userDetails);
              }
            );
          }
        } else {
          await createUser(userDetails);
        }
      }
    },
  },
});
